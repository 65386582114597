import React, {
  useState,
  useEffect,
  useCallback,
  memo,
  useRef,
  useMemo,
} from "react";
import Form, {
  SimpleItem,
  GroupItem,
  ButtonItem,
  AsyncRule,
  EmptyItem,
  CustomRule,
} from "devextreme-react/form";
import { useAuth0 } from "../../react-auth0-spa";
import notify from "devextreme/ui/notify";
import { PlannedRun, newRun, defaultSpatialManifest } from "./constants";
import { LoadPanel } from "devextreme-react/load-panel";
import { NumericRule } from "devextreme-react/data-grid";

const preferredSpatialBranch =
  "v27x-101124-Driftv2AddCP01C002-S2Img-ImprovedPostWash-ImprovedPriming-BridgingStudy";
export interface AddRunFormProps {
  visible: boolean;
  selectedRun: PlannedRun;
  isEditing: boolean;
  onSubmit: (data: PlannedRun, editedData: any) => Promise<boolean>;
  onCancel: () => void;
}

const okButtonOptions = {
  text: "OK",
  type: "default",
  useSubmitBehavior: true,
  width: "100%",
  stylingMode: "contained",
};

const logLevels = ["Debug", "Fatal", "Info", "Warnings"];

const saveIntensitiesOptions = ["none", "some", "all"];

const runLengths = [
  { name: "Half-day", value: 6 },
  { name: "Day", value: 24 },
  { name: "Two-day", value: 48 },
];

const prepMethods = ["adept", "elevate", "third party"];

const readOrders = ["I1,I2,R1,R2", "R1,I1,I2,R2", "R1,R2,I1,I2", "R1,I1,R2,I2"];

const chemistryVersions = [
  "Cloudbreak",
  "CloudbreakFS",
  "CloudbreakUQ",
  "Trinity",
];

const libraryTypes = ["Circular", "Linear"];

const kitSizes = ["150Cycles", "300Cycles", "600Cycles"];

const lowDiversityOptions = [
  { name: "No (use defualt PMG Mask)", value: "" },
  { name: "Yes (set PMG mask to I1:Y4N*)", value: "I1:Y4N*" },
];

const polonyDensityOptions = [
  { name: "Standard Cloudbreak (0.5)", value: 0.5 },
  { name: "Standard CloudbreakFS (0.45)", value: 0.45 },
  { name: "High Density (0.3)", value: 0.3 },
];

const wellLayoutOptions = [
  { name: "Twelve Well - Circle", value: "TwelveWellCircle" },
  { name: "Twelve Well - Standard", value: "TwelveWellStandard" },
  { name: "Mega Well", value: "OneWell" },
  // { name: "Two Well", value: "TwoWell"},
];

const kitTypes = [
  "OneHundredFiftyCycles",
  "ThreeHundredCycles",
  "SixHundredCycles",
];

const targetedReads = ["High", "Med", "Low"];

const sequencingPanelIDs = [
  "Other",
  "TWIST-Trinity-Exome-Workflow",
  "IDT-xGen-Exome-Kit",
];

const AddRunForm = memo((props: AddRunFormProps) => {
  const { loading, fetchWithCheck, myFetch } = useAuth0();
  const [lookups, setLookups] = useState({
    libraries: [],
    groups: [],
    tags: [],
    tileSelectionModules: [],
    recipeBranches: [],
    emails: [],
    projects: [],
    panelFiles: [],
  });
  const [data, setData] = useState<PlannedRun>();
  const [editedData, setEditedData] = useState({});
  const theForm = useRef(null);
  const [analysisCntr, setAnalysisCntr] = useState(0);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [spatialPanelParseMessage, setSpatialPanelParseMessage] = useState("");
  const [spatialZEditEnabled, setSpatialZEditEnabled] = useState(false);

  console.log("selected run", props.selectedRun);
  if (analysisCntr < 0) console.log("just to get rid of lint warning");

  const cancelButtonOptions = {
    text: "Cancel",
    type: "default",
    useSubmitBehavior: true,
    width: "100%",
    stylingMode: "outlined",
    onClick: () => {
      props.onCancel();
    },
  };

  useEffect(() => {
    !loading &&
      fetchWithCheck("/libraries").then((vals) => {
        const libraries = vals.filter((v) => v.id !== 0);
        setLookups((v) => ({ ...v, libraries }));
      });
  }, [loading, fetchWithCheck]);

  useEffect(() => {
    fetchWithCheck("/groups").then((groups) =>
      setLookups((v) => ({ ...v, groups }))
    );
  }, [loading, fetchWithCheck]);

  useEffect(() => {
    fetchWithCheck("/emails").then((emails) =>
      setLookups((v) => ({ ...v, emails }))
    );
  }, [loading, fetchWithCheck]);

  useEffect(() => {
    fetchWithCheck("/projects").then((projects) =>
      setLookups((v) => ({ ...v, projects }))
    );
  }, [loading, fetchWithCheck]);

  useEffect(() => {
    fetchWithCheck("/tags").then((tags) => setLookups((v) => ({ ...v, tags })));
  }, [loading, fetchWithCheck]);

  useEffect(() => {
    fetchWithCheck("/tileSelectionModules").then((tsm) => {
      const tileSelectionModules = [];
      let found = !props.isEditing;
      for (let i = 0; i < tsm.length; i++) {
        if (
          props.isEditing &&
          props.selectedRun &&
          props.selectedRun.tileSelectionModule ===
            `tile_selections/${tsm[i]}.txt`
        )
          found = true;

        tileSelectionModules.push({
          name: tsm[i],
          value: `tile_selections/${tsm[i]}.txt`,
        });
      }

      if (!found)
        tileSelectionModules.push({
          value: props.selectedRun.tileSelectionModule,
          name: props.selectedRun.tileSelectionModule
            .replaceAll("tile_selections/", "")
            .replaceAll(".txt", ""),
        });

      setLookups((v) => ({ ...v, tileSelectionModules }));
    });
  }, [loading, fetchWithCheck, props.isEditing, props.selectedRun]);

  useEffect(() => {
    if (!(window as any).recipeBranches || forceRefresh) {
      setFetching(true);
      setForceRefresh(false);
      fetchWithCheck("/recipeBranches")
        .then((recipeBranches) => {
          setLookups((v) => ({ ...v, recipeBranches }));
          (window as any).recipeBranches = recipeBranches;
        })
        .finally(() => setFetching(false));
    } else {
      setLookups((v) => ({
        ...v,
        recipeBranches: (window as any).recipeBranches,
      }));
    }
  }, [loading, fetchWithCheck, forceRefresh]);

  console.log(
    "filter",
    lookups.recipeBranches.filter((v) =>
      v.name.startsWith("Spaceman-Protein-RNA-RCA-H")
    )
  );

  useEffect(() => {
    if (!(window as any).panelFiles || forceRefresh) {
      setFetching(true);
      setForceRefresh(false);
      fetchWithCheck("/panels")
        .then((panelFiles) => {
          setLookups((v) => ({ ...v, panelFiles: panelFiles }));
          (window as any).panelFiles = panelFiles;
        })
        .finally(() => setFetching(false));
    } else {
      setLookups((v) => ({
        ...v,
        panelFiles: (window as any).panelFiles,
      }));
    }
  }, [loading, fetchWithCheck, forceRefresh]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      console.log("edited data", editedData);

      if (
        "panelFilePath" in editedData &&
        editedData["panelFilePath"] == null
      ) {
        // if the panelFilePath was edited but set to a null value, set it to an empty string instead
        // otherwise the value will not be updated during a patch
        editedData["panelFilePath"] = "";
      }

      // set batch info and spatialPanelJson to empty if a panelFilePath is set
      if (data.panelFilePath) {
        data.batchInfo = [];
        data.spatialPanelJson = "";
        data.spatialPanel = "";
      }
      if (editedData["panelFilePath"]) {
        editedData["batchInfo"] = [];
        editedData["spatialPanelJson"] = "";
        editedData["spatialPanel"] = "";
      }

      if (
        !editedData["spatialPanelJson"] &&
        editedData["spatialPanel"] &&
        data.spatialPanelJson
      ) {
        editedData["spatialPanelJson"] = data.spatialPanelJson;
      }

      if (!props.onSubmit(data, editedData)) {
        notify(
          {
            message: "Failed to create run",
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "error",
          3000
        );
      }
    },
    [props, data, editedData]
  );

  const handleManifestSelected = useCallback((e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      let manifestStr = String(reader.result);
      // replace any CRLF characters in the file with just LF
      manifestStr = manifestStr.replace(/\r\n/g, "\n");
      setData((v) => {
        return { ...v, manifest: manifestStr };
      });
      setEditedData((v) => {
        return { ...v, manifest: manifestStr };
      });

      theForm.current.instance.validate();
    };
    reader.readAsText(file);
  }, []);

  const handleSpatialPanelSelected = useCallback((e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      let panelStr = String(reader.result);
      // replace CRLF characters in the file with just LF
      panelStr = panelStr.replace(/\r\n/g, "\n");
      setData((v) => {
        return { ...v, spatialPanel: panelStr };
      });
      setEditedData((v) => {
        return { ...v, spatialPanel: panelStr };
      });

      theForm.current.instance.validate();
    };
    reader.readAsText(file);
  }, []);

  useEffect(() => {
    setData({
      ...props.selectedRun,
      spatialPanel: props.selectedRun.spatialPanelJson,
      spatialPanelType: props.selectedRun.spatialPanelJson ? "JSON" : undefined,
    });
    setEditedData({
      batchInfo: props.selectedRun
        ? props.selectedRun.batchInfo
        : newRun.batchInfo,
    });
  }, [props.selectedRun]);

  const [panelFileSelected, setPanelFileSelected] = useState<boolean>(
    props.selectedRun ? !!props.selectedRun.panelFilePath : false
  );

  const isSequencing = data?.runType === "Sequencing";
  const isSpatial = data?.runType === "Spatial";
  const isTrinity = data?.chemistryVersion === "Trinity";

  console.log("mydata", data);
  console.log("editeddata", editedData);
  return (
    <div id="myform">
      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          id="form"
          ref={theForm}
          formData={data}
          readOnly={false}
          showColonAfterLabel={true}
          labelLocation={"left"}
          colCount={10}
          showValidationSummary={true}
          validationGroup="runData"
          style={useMemo(() => ({ paddingRight: 50 }), [])}
          onFieldDataChanged={useCallback(
            (e) => {
              if (e.dataField === "groupID") {
                if (e.value === 0) {
                  setData((d) => {
                    return { ...d, runCountNumber: 0, runName: "" };
                  });
                } else {
                  const grp = lookups.groups.find((v) => v.id === e.value);
                  if (grp == null) return;
                  const rname =
                    grp.abbr +
                    "-" +
                    (grp.runCount + 1).toString().padStart(4, "0");
                  setData((d) => {
                    return {
                      ...d,
                      runCountNumber: grp.runCount + 1,
                      runName: rname,
                    };
                  });
                }
              } else if (e.dataField === "panelFilePath") {
                if (e.value) {
                  setPanelFileSelected(true);
                } else {
                  setPanelFileSelected(false);
                }
              } else if (e.dataField === "spatialNumBatches") {
                if (!props.selectedRun || analysisCntr > 0)
                  e.component.updateData(
                    "batchInfo",
                    Array.from({ length: e.value }, () => ({
                      numCycles: 12,
                      pmgMask: "Y4N*",
                      indexMask: "N4Y8N*",
                    }))
                  );
                setAnalysisCntr((v) => v + 1);
              } else if (
                e.dataField === "analysisEnabled" ||
                e.dataField === "cloudAnalysis" ||
                e.dataField === "dualLibrary" ||
                e.dataField === "runType"
              ) {
                if (e.dataField === "runType") {
                  if (e.value === "Spatial") {
                    // Update the default manifest for spatial if the current value matches the default for sequencing,
                    // and it's a new run (not editing)
                    if (
                      !props.isEditing &&
                      data.manifest === newRun.manifest &&
                      editedData["manifest"] === undefined
                    ) {
                      e.component.updateData(
                        "manifest",
                        defaultSpatialManifest
                      );
                      e.component.updateData(
                        "recipeBranch",
                        preferredSpatialBranch
                      );
                    }

                    e.component.updateData("cloudAnalysis", true);
                    e.component.updateData(
                      "analysisToolRep",
                      "registry.gitlab.com/elembio/analysis/primaryanalysisdotnetcore"
                    );
                    e.component.updateData(
                      "imageName",
                      "registry.gitlab.com/elembio/analysis/primaryanalysisdotnetcore:3"
                    );
                    e.component.updateData(
                      "arguments",
                      "bash /primaryanalysisdotnetcore/run_container_analysis.sh -ASSAYMODE=3D -RUNMANIFEST=RunManifest.json"
                    );
                    setEditedData((v) => ({
                      ...v,
                      batchInfo: { ...data.batchInfo },
                    }));
                  }
                } else if (e.dataField === "dualLibrary" && e.value) {
                  e.component.updateData("l2LibraryID", null);
                  e.component.updateData("l2LibraryConcentration", "");
                }
                setAnalysisCntr((v) => v + 1);
              } else if (e.dataField === "chemistryVersion") {
                // e.component.updateData(
                //   "readOrder",
                //   e.value === "1" ? "R1,R2,I1,I2" : "I1,I2,R1,R2"
                // );
                if (e.value === "Trinity") {
                  e.component.updateData("preparationMethod", "elevate");
                  e.component.updateData("libraryType", "Linear");
                  e.component.updateData("sequencingPanelID", "Other");
                } else {
                  e.component.updateData("sequencingPanelID", "");
                }
                setAnalysisCntr((v) => v + 1);
              } else if (e.dataField === "requester") {
                e.component.updateData("operator", e.value);
              } else if (e.dataField === "saveIntensities") {
                e.component.updateData(
                  "uploadIntensities",
                  e.value === "some" || e.value === "all"
                );
              } else if (e.dataField === "spatialPanel") {
                e.component.validate();
              } else if (e.dataField === "enableEditingZSettings") {
                setSpatialZEditEnabled(e.value);
              } else if (e.dataField === "recipeBranch") {
                setAnalysisCntr((v) => v + 1);
              }
              setEditedData((tmp: PlannedRun) => {
                if (!tmp.cloudAnalysis && tmp.analysisEnabled) {
                  tmp.roiWidth = newRun.roiWidth;
                  tmp.roiHeight = newRun.roiHeight;
                }
                tmp[e.dataField] = e.value;
                return tmp;
              });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [lookups.groups, data?.batchInfo, analysisCntr, props.selectedRun]
          )}
        >
          <GroupItem colSpan={10}>
            <GroupItem colCount={4} caption={"Run Parameters"}>
              <SimpleItem
                dataField="runType"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: ["Sequencing", "Spatial"],
                  }),
                  []
                )}
                colSpan={4}
                isRequired={true}
              />
              <SimpleItem
                dataField="groupID"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    displayExpr: "name",
                    searchMode: "startswith",
                    searchExpr: "name",
                    searchEnabled: true,
                    showClearButton: true,
                    valueExpr: "id",
                    items: lookups.groups,
                    readOnly:
                      props.isEditing && props.selectedRun?.groupID !== 0,
                  }),
                  [lookups.groups, props.isEditing, props.selectedRun]
                )}
                colSpan={4}
                isRequired={true}
              />
              <SimpleItem
                dataField="runName"
                editorOptions={useMemo(
                  () => ({ readOnly: data && data.groupID }),
                  [data]
                )}
                isRequired={true}
                colSpan={4}
              />
              <SimpleItem
                dataField="runLength"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    displayExpr: "name",
                    valueExpr: "value",
                    items: runLengths,
                  }),
                  []
                )}
                isRequired={true}
                colSpan={4}
              />

              <SimpleItem
                dataField="requester"
                colSpan={4}
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: lookups.emails,
                    searchMode: "startswith",
                    searchEnabled: true,
                    showClearButton: true,
                  }),
                  [lookups.emails]
                )}
                isRequired
              />

              <SimpleItem
                dataField="project"
                colSpan={4}
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: lookups.projects,
                    searchMode: "startswith",
                    searchEnabled: true,
                    showClearButton: true,
                  }),
                  [lookups.projects]
                )}
                isRequired
              />

              <SimpleItem
                dataField="tileSelectionModule"
                colSpan={4}
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: lookups.tileSelectionModules,
                    displayExpr: "name",
                    valueExpr: "value",
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      const newitem = {
                        name: e.text,
                        value: `tile_selections/${e.text}.txt`,
                      };
                      console.log("new item", newitem);
                      lookups.tileSelectionModules.push(newitem);
                      e.customItem = newitem;
                    },
                  }),
                  [lookups.tileSelectionModules]
                )}
                isRequired={true}
                // helpText="do not include tile_selections/ prefix or the .txt suffix"
              />
              <SimpleItem
                dataField="chemistryVersion"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: chemistryVersions,
                  }),
                  []
                )}
                isRequired={isSequencing}
                colSpan={4}
                visible={isSequencing}
              />
              <SimpleItem
                dataField="sequencingPanelID"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: sequencingPanelIDs,
                  }),
                  []
                )}
                isRequired={isSequencing && isTrinity}
                colSpan={4}
                visible={isSequencing && isTrinity}
              />
              <SimpleItem
                dataField="preparationMethod"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: prepMethods,
                  }),
                  []
                )}
                isRequired={isSequencing}
                colSpan={4}
                visible={isSequencing}
              />
              <SimpleItem
                dataField="libraryType"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: libraryTypes,
                  }),
                  []
                )}
                isRequired={isSequencing}
                colSpan={4}
                visible={isSequencing}
              />
              <SimpleItem
                dataField="kitConfiguration"
                label={{ text: "Kit Size" }}
                editorType="dxSelectBox"
                helpText="This option is relevant for AOS versions 2.2.x and earlier"
                editorOptions={useMemo(
                  () => ({
                    items: kitSizes,
                  }),
                  []
                )}
                isRequired={isSequencing}
                colSpan={4}
                visible={isSequencing}
              />
              <SimpleItem
                dataField="kitType"
                label={{ text: "Kit Type" }}
                editorType="dxSelectBox"
                helpText="This option is relevant for AOS versions 2.3.0 and above"
                editorOptions={useMemo(
                  () => ({
                    items: kitTypes,
                  }),
                  []
                )}
                isRequired={isSequencing}
                colSpan={4}
                visible={isSequencing}
              />

              <SimpleItem
                dataField="pmgMask"
                editorType="dxSelectBox"
                label={{ text: "Low Diversity Amplicon" }}
                editorOptions={useMemo(
                  () => ({
                    displayExpr: "name",
                    valueExpr: "value",
                    items: lowDiversityOptions,
                  }),
                  []
                )}
                colSpan={4}
                helpText="Requires AOS version >= 2.4.0 and should only be set to YES for Adept 2x300 with high number of indexes"
                visible={isSequencing}
              />

              <SimpleItem
                dataField="pmgThreshold"
                editorType="dxSelectBox"
                label={{ text: "Polony Density" }}
                editorOptions={useMemo(
                  () => ({
                    displayExpr: "name",
                    valueExpr: "value",
                    items: polonyDensityOptions,
                  }),
                  []
                )}
                colSpan={4}
                helpText="Requires AOS version >= 2.6.0"
                visible={isSequencing}
                isRequired={isSequencing}
              />

              <SimpleItem
                dataField="readOrder"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: readOrders,
                  }),
                  []
                )}
                isRequired={isSequencing}
                colSpan={4}
                visible={isSequencing}
              />

              <SimpleItem
                label={useMemo(
                  () => ({ location: "top", text: "R1 Cycles" }),
                  []
                )}
                dataField="r1Cycles"
                colSpan={1}
                visible={isSequencing}
              />
              <SimpleItem
                label={useMemo(
                  () => ({ location: "top", text: "R2Cycles" }),
                  []
                )}
                dataField="r2Cycles"
                colSpan={1}
                visible={isSequencing}
              />
              <SimpleItem
                label={useMemo(
                  () => ({ location: "top", text: "I1 Cycles" }),
                  []
                )}
                dataField="i1Cycles"
                colSpan={1}
                visible={isSequencing}
              />
              <SimpleItem
                label={useMemo(
                  () => ({ location: "top", text: "I2 Cycles" }),
                  []
                )}
                dataField="i2Cycles"
                colSpan={1}
                visible={isSequencing}
              />

              <SimpleItem
                dataField="recipeBranch"
                colSpan={4}
                cssClass="error"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: lookups.recipeBranches,
                    displayExpr: "name",
                    valueExpr: "name",
                    searchMode: "startswith",
                    searchExpr: "name",
                    searchEnabled: true,
                    showClearButton: true,
                  }),
                  [lookups.recipeBranches]
                )}
                isRequired={false}
                helpText={
                  isSequencing
                    ? ""
                    : data?.recipeBranch !== preferredSpatialBranch
                    ? "Note: please use " + preferredSpatialBranch
                    : ""
                }
              />

              <EmptyItem colSpan={1} />
              <ButtonItem
                buttonOptions={useMemo(
                  () => ({
                    text: "Refresh Recipes",
                    type: "default",
                    stylingMode: "outlined",
                    onClick: () => {
                      setForceRefresh(true);
                    },
                  }),
                  []
                )}
                colSpan={1}
              />
              <EmptyItem colSpan={2} />

              <SimpleItem
                dataField="tags"
                colSpan={4}
                editorType="dxTagBox"
                editorOptions={useMemo(
                  () => ({
                    items: lookups.tags,
                    searchEnabled: true,
                  }),
                  [lookups.tags]
                )}
              />
              <SimpleItem
                dataField="description"
                colSpan={4}
                validationRules={useMemo(
                  () => [
                    {
                      type: "pattern",
                      pattern: "^[A-Za-z0-9\\-_. ]+$",
                      message:
                        "Only alphanumeric characters, as well as spaces, preiods and hyphens are allowed in the description",
                    },
                  ],
                  []
                )}
              />

              <SimpleItem
                dataField="enableEditingZSettings"
                label={{ text: "Enable Editing Z-Settings" }}
                colSpan={4}
                visible={isSpatial}
                editorType="dxCheckBox"
              />

              <SimpleItem
                isRequired={isSpatial}
                label={useMemo(() => ({ text: "Num. Z Slices" }), [])}
                dataField="numSpatialZSlices"
                colSpan={4}
                visible={isSpatial}
                editorOptions={{ disabled: !spatialZEditEnabled }}
              >
                <NumericRule
                  message="Must be a number"
                  ignoreEmptyValue={false}
                />
                <CustomRule
                  message="Must be a number between 1 and 20"
                  validationCallback={(e) => {
                    if (+e.value < 1 || +e.value > 20) {
                      return false;
                    }
                    return true;
                  }}
                />
              </SimpleItem>
              <SimpleItem
                isRequired={isSpatial}
                label={useMemo(() => ({ text: "Z-Slice Offset" }), [])}
                dataField="zSliceOffset"
                colSpan={4}
                visible={isSpatial}
                editorOptions={{ disabled: !spatialZEditEnabled }}
              >
                <CustomRule
                  message="Must be a valid floating point number"
                  validationCallback={(e) => {
                    if (isNaN(e.value) || Number.isNaN(parseFloat(e.value))) {
                      return false;
                    }
                    return true;
                  }}
                />
              </SimpleItem>
              <SimpleItem
                isRequired={isSpatial}
                label={useMemo(() => ({ text: "Z-Slice Pitch" }), [])}
                dataField="zSlicePitch"
                colSpan={4}
                visible={isSpatial}
                editorOptions={{ disabled: !spatialZEditEnabled }}
              >
                <CustomRule
                  message="Must be a valid floating point number"
                  validationCallback={(e) => {
                    if (isNaN(e.value) || Number.isNaN(parseFloat(e.value))) {
                      return false;
                    }
                    return true;
                  }}
                />
              </SimpleItem>
              <SimpleItem
                dataField="wellLayout"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: wellLayoutOptions,
                    displayExpr: "name",
                    valueExpr: "value",
                  }),
                  []
                )}
                isRequired={isSpatial}
                colSpan={4}
                visible={isSpatial}
              >
                <AsyncRule
                  validationCallback={useCallback(
                    (params) =>
                      new Promise<void>((resolve, reject) => {
                        const pick = ({ runName, manifest, wellLayout }) => ({
                          runName,
                          manifest,
                          wellLayout,
                        });
                        const tdata = pick(data);
                        tdata.wellLayout = params.value;

                        const payload = pick(tdata);
                        if (isSpatial) {
                          console.log("manifest verify payload", payload);
                          myFetch("/spatialManifest", {
                            method: "POST",
                            body: JSON.stringify({
                              ...payload,
                            }),
                          })
                            .then((response) => response.json())
                            .then((resp) => {
                              if (!resp.isValid)
                                reject(
                                  "WellLayout is not compatible with manifest: " +
                                    resp.message
                                );
                              else resolve();
                            })
                            .catch((error) => {
                              reject(
                                "Cannot validate manifest: " +
                                  JSON.stringify(error)
                              );
                            });
                        }
                      }),
                    [data, myFetch, isSpatial]
                  )}
                />
              </SimpleItem>

              <SimpleItem
                dataField="panelFilePath"
                colSpan={3}
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: lookups.panelFiles,
                    displayExpr: "name",
                    valueExpr: "path",
                    searchMode: "startswith",
                    searchExpr: "name",
                    searchEnabled: true,
                    showClearButton: true,
                  }),
                  [lookups.panelFiles]
                )}
                isRequired={false}
                visible={isSpatial}
              />
              <ButtonItem
                buttonOptions={useMemo(
                  () => ({
                    text: "Refresh",
                    type: "default",
                    stylingMode: "outlined",
                    onClick: () => {
                      setForceRefresh(true);
                    },
                  }),
                  []
                )}
                colSpan={1}
                visible={isSpatial}
              />
            </GroupItem>
            {/* <GroupItem caption="Notifications">
              <SimpleItem
                dataField="notificationEmails"
                helpText="use semicolons to separate multiple emails"
              />
            </GroupItem> */}

            <SimpleItem
              dataField="targetedReads"
              editorType="dxSelectBox"
              editorOptions={useMemo(
                () => ({
                  items: targetedReads,
                }),
                []
              )}
              isRequired={isSequencing}
              visible={isSequencing}
              colSpan={4}
            />

            <SimpleItem
              dataField="dualLibrary"
              colSpan={10}
              helpText="This option is only compatible with AOS versions 2.3.0 and above"
              visible={isSequencing}
            />
            <SimpleItem
              dataField="singleLane"
              colSpan={10}
              helpText="This option is only compatible with AOS versions 2.3.0 and above"
              visible={isSequencing}
            />
            <GroupItem
              caption={
                data?.dualLibrary
                  ? "L1 Library Information"
                  : "Library Information"
              }
              visible={isSequencing}
            >
              <SimpleItem
                dataField="libraryID"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    displayExpr: "name",
                    valueExpr: "id",
                    searchExpr: "name",
                    showClearButton: true,
                    searchEnabled: true,
                    items: lookups.libraries,
                    readOnly: false,
                  }),
                  [lookups.libraries]
                )}
                isRequired={isSequencing}
                colSpan={1}
              />
              <SimpleItem
                dataField="libraryConcentration"
                isRequired={isSequencing}
                editorType="dxNumberBox"
              />

              <SimpleItem
                dataField="spikeInLibraryID"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    displayExpr: "name",
                    valueExpr: "id",
                    searchExpr: "name",
                    showClearButton: true,
                    searchEnabled: true,
                    items: lookups.libraries,
                    readOnly: false,
                  }),
                  [lookups.libraries]
                )}
                colSpan={1}
              />
              <SimpleItem dataField="spikeInLibraryConcentration" />
              <SimpleItem dataField="libraryDilutionLot" />
              <SimpleItem dataField="libraryMixStockLot" />
            </GroupItem>

            <GroupItem
              visible={data?.dualLibrary && isSequencing}
              caption="L2 Library Information"
            >
              <SimpleItem
                dataField="l2LibraryID"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    displayExpr: "name",
                    valueExpr: "id",
                    searchExpr: "name",
                    showClearButton: true,
                    searchEnabled: true,
                    items: lookups.libraries,
                    readOnly: false,
                  }),
                  [lookups.libraries]
                )}
                isRequired={isSequencing}
                colSpan={1}
              />
              <SimpleItem
                dataField="l2LibraryConcentration"
                isRequired={isSequencing}
                editorType="dxNumberBox"
              />

              <SimpleItem
                dataField="l2SpikeInLibraryID"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    displayExpr: "name",
                    valueExpr: "id",
                    searchExpr: "name",
                    showClearButton: true,
                    searchEnabled: true,
                    items: lookups.libraries,
                    readOnly: false,
                  }),
                  [lookups.libraries]
                )}
                colSpan={1}
              />
              <SimpleItem dataField="l2SpikeInLibraryConcentration" />
              <SimpleItem dataField="l2LibraryDilutionLot" />
              <SimpleItem dataField="l2LibraryMixStockLot" />
            </GroupItem>
          </GroupItem>
          <GroupItem colSpan={10}>
            <GroupItem
              caption="Spatial Panel"
              visible={isSpatial && !panelFileSelected}
            >
              <SimpleItem
                dataField="spatialPanelType"
                editorType="dxSelectBox"
                editorOptions={useMemo(
                  () => ({
                    items: ["CSV", "JSON"],
                  }),
                  []
                )}
                isRequired={false}
                colSpan={2}
              />
              <SimpleItem
                visible={isSpatial && !panelFileSelected}
                render={useCallback(
                  () => (
                    <div>
                      <label
                        htmlFor="spatial-panel-file-upload"
                        style={{
                          border: "1px solid #ccc",
                          display: "inline-block",
                          padding: "6px 12px",
                          cursor: "pointer",
                        }}
                      >
                        Load Spatial Panel...
                      </label>
                      <input
                        id="spatial-panel-file-upload"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleSpatialPanelSelected}
                      />
                    </div>
                  ),
                  [handleSpatialPanelSelected]
                )}
              />
              <SimpleItem
                visible={isSpatial && !panelFileSelected}
                isRequired={isSpatial && !panelFileSelected}
                dataField="spatialPanel"
                label={{ visible: false }}
                helpText={
                  "Spatial Panel" +
                  (spatialPanelParseMessage
                    ? ": " + spatialPanelParseMessage
                    : "")
                }
                editorType="dxTextArea"
                editorOptions={useMemo(
                  () => ({
                    height: 210,
                    spellcheck: false,
                  }),
                  []
                )}
              >
                <AsyncRule
                  validationCallback={useCallback(
                    (params) =>
                      new Promise<void>((resolve, reject) => {
                        const pick = ({
                          runName,
                          spatialPanelType,
                          spatialPanel,
                        }) => ({
                          runName,
                          spatialPanelType,
                          spatialPanel,
                        });
                        const payload = pick(data);
                        payload.spatialPanel = params.value;
                        console.log("panel verify payload", payload);
                        if (!payload.spatialPanelType) {
                          reject("Please select panel type to validate");
                        }
                        if (!payload.spatialPanel) {
                          reject("Please enter spatial panel");
                        }
                        myFetch("/panelValidation", {
                          method: "POST",
                          body: JSON.stringify({
                            ...payload,
                          }),
                        })
                          .then((response) => response.json())
                          .then((resp) => {
                            if (!resp.isValid) {
                              setSpatialPanelParseMessage("");
                              reject("Spatial Panel: " + resp.message);
                            } else {
                              setData((v) => {
                                return {
                                  ...v,
                                  spatialPanelJson: resp.panelJson,
                                };
                              });
                              setSpatialPanelParseMessage(resp.message);
                              resolve();
                            }
                          })
                          .catch((error) => {
                            reject(
                              "Cannot validate panel: " + JSON.stringify(error)
                            );
                          });
                      }),
                    [data, myFetch]
                  )}
                />
              </SimpleItem>
            </GroupItem>
            <GroupItem caption="Manifest">
              <SimpleItem
                dataField="manifest"
                label={{ visible: false }}
                helpText={
                  isSpatial
                    ? ""
                    : "I1Cycles, I2Cycles, R1Cycles, R2Cycles, KitConfiguration, PreparationMethod and TileSelectionModule are now ignored and will be overridden by the the values you select in the UI"
                }
                editorType="dxTextArea"
                editorOptions={useMemo(
                  () => ({
                    height: 210,
                    spellcheck: false,
                  }),
                  []
                )}
              >
                <AsyncRule
                  validationCallback={useCallback(
                    (params) =>
                      new Promise<void>((resolve, reject) => {
                        const pick = ({
                          runName,
                          manifest,
                          i1Cycles,
                          i2Cycles,
                          r1Cycles,
                          r2Cycles,
                          tileSelectionModule,
                          preparationMethod,
                          chemistryVersion,
                          targetedReads,
                          kitConfiguration,
                          kitType,
                          wellLayout,
                        }) => ({
                          runName,
                          manifest,
                          i1Cycles,
                          i2Cycles,
                          r1Cycles,
                          r2Cycles,
                          tileSelectionModule,
                          chemistryVersion,
                          targetedReads,
                          preparationMethod:
                            preparationMethod === "third party"
                              ? "ThirdParty"
                              : preparationMethod,
                          kitConfiguration,
                          kitType,
                          wellLayout,
                        });
                        console.log("data", data);
                        const tdata = pick(data);
                        tdata.manifest = params.value;

                        if (/\r\n/g.test(params.value)) {
                          reject("Manifest cannot contain CRLF characters");
                        }

                        const payload = pick(tdata);
                        if (isSpatial) {
                          console.log("manifest verify payload", payload);
                          myFetch("/spatialManifest", {
                            method: "POST",
                            body: JSON.stringify({
                              ...payload,
                            }),
                          })
                            .then((response) => response.json())
                            .then((resp) => {
                              if (!resp.isValid)
                                reject("Manifest: " + resp.message);
                              else resolve();
                            })
                            .catch((error) => {
                              reject(
                                "Cannot validate manifest: " +
                                  JSON.stringify(error)
                              );
                            });
                        } else {
                          const mappedKitConfig =
                            kitSizes[kitTypes.indexOf(payload.kitType)];

                          const newpayload = {
                            ...payload,
                            kitConfiguration: mappedKitConfig,
                            kitSize: mappedKitConfig,
                          };

                          console.log("manifest verify payload", newpayload);
                          myFetch("/manifest", {
                            method: "POST",
                            body: JSON.stringify({
                              ...newpayload,
                              kitConfiguration: mappedKitConfig,
                              kitSize: mappedKitConfig,
                            }),
                          })
                            .then((response) => response.json())
                            .then((resp) => {
                              if (!resp.isValid)
                                reject("Manifest: " + resp.message);
                              else resolve();
                            })
                            .catch((error) => {
                              reject(
                                "Cannot validate manifest: " +
                                  JSON.stringify(error)
                              );
                            });
                        }
                      }),
                    [data, myFetch, isSpatial]
                  )}
                />
              </SimpleItem>
              <SimpleItem
                render={useCallback(
                  () => (
                    <div>
                      <label
                        htmlFor="file-upload"
                        style={{
                          border: "1px solid #ccc",
                          display: "inline-block",
                          padding: "6px 12px",
                          cursor: "pointer",
                        }}
                      >
                        Load Manifest...
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleManifestSelected}
                      />
                    </div>
                  ),
                  [handleManifestSelected]
                )}
              ></SimpleItem>
            </GroupItem>
            <GroupItem caption="Analysis Settings" colCount={1}>
              <GroupItem colCount={1}>
                <SimpleItem
                  dataField="analysisEnabled"
                  helpText={
                    data &&
                    !data.analysisEnabled &&
                    "For 2.0.0-rc and later, no images will be saved in this mode. To save images without any analysis, enable analysis, choose cloud analysis, and delete the job information. (Leave the ROI settings)"
                  }
                />
                <SimpleItem
                  dataField="cloudAnalysis"
                  label={useMemo(
                    () => ({ text: "Cloud Analysis Only (no OBPA)" }),
                    []
                  )}
                  visible={data && data.analysisEnabled}
                />
              </GroupItem>
              <GroupItem
                visible={data && data.cloudAnalysis && data.analysisEnabled}
              >
                <SimpleItem dataField="analysisToolRep" />
                <SimpleItem dataField="imageName" />
                <SimpleItem dataField="arguments" />
                <GroupItem colCount={1}>
                  <SimpleItem dataField="roiWidth" />
                  <SimpleItem dataField="roiHeight" />
                </GroupItem>
              </GroupItem>

              <GroupItem
                colCount={1}
                visible={data && !data.cloudAnalysis && data.analysisEnabled}
              >
                <SimpleItem
                  dataField="logLevel"
                  editorType="dxSelectBox"
                  editorOptions={useMemo(
                    () => ({
                      items: logLevels,
                    }),
                    []
                  )}
                />
                <EmptyItem />

                <SimpleItem
                  dataField="saveIntensities"
                  editorType="dxSelectBox"
                  editorOptions={useMemo(
                    () => ({
                      items: saveIntensitiesOptions,
                    }),
                    []
                  )}
                />
                <SimpleItem
                  dataField="zipCNNImages"
                  label={useMemo(()=> ({text: "Zip CNN Images"}), [])}
                  visible={isSpatial}
                />
              </GroupItem>
            </GroupItem>
          </GroupItem>

          <ButtonItem
            //   horizontalAlignment="center"
            buttonOptions={okButtonOptions}
            colSpan={3}
          />
          <ButtonItem
            //   horizontalAlignment="center"
            buttonOptions={cancelButtonOptions}
            colSpan={3}
          />
        </Form>
      </form>
      <LoadPanel visible={fetching} showIndicator={true} showPane={true} />
    </div>
  );
});

export default AddRunForm;
